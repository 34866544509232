<template>
  <div name="evidence" >
    <div
      style="width: 100%; text-align: center"
      class="spin-holder"
      v-if="loading"
    >
      <i class="spinner fa fa-atom fa-spin"></i>
      <p>Loading Evidence</p>
    </div>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <ul class="clear flex mb-12 ml-2 pl-3" v-if="!loading">
      <li class="mr-6">
        <dropdown
          v-if="lessons.length > 0"
          :options="lessonDropdown"
          :css="'text-white'"
          :selected="lesson"
          v-on:updateOption="lessonSelect"
          class="w-40 bg-orange-300"
        ></dropdown>
      </li>
      <li class="mr-1">
        <button
          type="button"
          @click="setDisplayType('')"
          class="
            mt-1
            text-white
            hover:bg-orange-300
            font-bold
            py-2
            px-4
            mr-2
            rounded
          "
          title="All"
          v-bind:class="displayType == '' ? 'bg-orange-300' : 'bg-gray-300'"
        >
          <i class="fas fa-border-all mr-1"></i> All
        </button>
      </li>
      <li class="mr-1">
        <button
          type="button"
          @click="setDisplayType('camera')"
          class="
            mt-1
            bg-gray-300
            text-white
            hover:bg-orange-300
            font-bold
            py-2
            px-4
            mr-2
            rounded
          "
          title="Images"
          v-bind:class="
            displayType == 'camera' ? 'bg-orange-300' : 'bg-gray-300'
          "
        >
          <i class="far fa-image mr-1"></i> Images
        </button>
      </li>
      <li class="mr-6">
        <button
          type="button"
          @click="setDisplayType('video')"
          class="
            mt-1
            bg-gray-300
            text-white
            hover:bg-orange-300
            font-bold
            py-2
            px-4
            mr-2
            rounded
          "
          title="Videos"
          v-bind:class="
            displayType == 'video' ? 'bg-orange-300' : 'bg-gray-300'
          "
        >
          <i class="fas fa-video mr-1"></i> Videos
        </button>
      </li>

      <li class="mr-1 inline" v-if="lesson.id != 0">
        <button
          type="button"
          @click="showAdditionWindow = true"
          class="
            mt-1
            bg-green-300
            hover:bg-green-500
            text-white
            font-bold
            py-2
            px-4
            mr-2
            rounded
          "
          title="Add Evidence"
        >
          <i class="fas fa-plus mr-1"></i> Add Evidence
        </button>
      </li>
      <li class="mr-1 inline" v-if="selectedEvidences.length > 0">
        <button
          type="button"
          @click="showMove = true"
          class="
            mt-1
            bg-purple-400
            hover:bg-purple-500
            text-white
            font-bold
            py-2
            px-4
            mr-2
            rounded
          "
          title="Move Evidence"
        >
          <i class="fas fa-expand-arrows-alt mr-1"></i> Move Evidence
        </button>
      </li>
    </ul>

    <savenotice :title="'Evidence'" ref="saveNotice" class="mb-2"></savenotice>


    <transition name="fade">
      <div class="loading" v-show="isLoadingMore">
        <span class="fa fa-spinner fa-spin"></span> Loading
      </div>
    </transition>

    <div
      class="px-auto grid grid-cols-4 lg:grid-cols-4 gap-2 gap-y-6 bg-white pb-10"
     v-if="componentLoaded"
      
    >
      <div class="relative" 
        v-for="(item, index) in filteredEvidences"
        :key="item.identifier"
      >


<div class="absolute w-full">
<i
          v-if="hasNotes(item)" 
          class="fas
                  fa-comment float-right
                  text-white p-2 text-2xl"></i>
</div>
        

        <div class="max-w-sm rounded-lg overflow-hidden shadow-xl">
  

          <evidencevideo
            v-if="item.media_type === 'video'"
            reference="'ref_'+index"
            :url="loadFile(item.media_url)"
            width="100%"
            height="100%"
          ></evidencevideo>
          <evidenceimage
            v-else
            :url="loadFile(item.media_url)"
            reference="'ref_'+index"
            style="max-height: 160px !important"
          ></evidenceimage>
          <div class="px-4 pt-2 pb-1">
            <div
              class="font-medium text-xs"
              v-html="getClassName(item)"
            ></div>
            <div class="text-xs ">
              {{ getLessonName(item) }}
            </div>
          </div>

          <div class="w-full">
            <button
              type="button"
              @click="selectEvidence(item)"
              class="
                inline-block
                font-bold
                ml-3
                mt-2
                mb-2
                rounded-full
                icon
                text-2xl
                hover:text-gray-600
              "
              v-bind:class="
                evidenceIsSelected(item)
                  ? 'text-red-400 fas fa-check-circle'
                  : 'text-gray-200 far fa-circle'
              "
            ></button>
            <button
              type="button"
              @click="showEvidenceAsset(item)"
              class="
                float-right
                mt-1
                text-gray-500
                hover:text-green-300
                font-bold
                py-1
                px-3
                mr-2
                mb-2
              "
            >
              <i
                class="
                  fas
                  fa-angle-right
                  text-gray-300 text-2xl
                  hover:text-gray-600
                "
              ></i>
            </button>
            <!-- <button
              type="button"
              disabled=""
              class="float-right mt-1 text-gray-500 hover:text-green-300 font-bold py-1 px-3 mr-2 mb-2"
              title="Videos"
            ></button> -->
          </div>
        </div>
      </div>
 
    </div>
           <p class="ml-5 mt-6 text-sm" v-else>
      We could not find any data for your given selection.
    </p>
  

    <transition name="modalwindow" v-if="showMove == true">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-full outline-none fade"
      >
        <div
          class="
            modal-container
            bg-white
            w-9/12
            mt-32
            mx-auto
            rounded
            shadow-lg
            z-50
            h-3/4
            border-b-8
          "
        >
          <div class="pt-2">
            <slot name="header">
              <i
                class="
                  fas
                  fa-times-circle
                  cursor-pointer
                  fixed
                  right-0
                  items-center
                  mt-3
                  mr-48
                  text-pink-red text-4xl
                  z-50
                "
                @click="closeMoveModal()"
              ></i>
              <h2 class="text-2xl m-3 text-center font-bold text-pink-red">
                Move Evidence
              </h2>

              <p class="ml-16 mt-6 mb-4 text-sm text-gray-600">
                Move the selected pieces of evidence to a different Class /
                Group and | or Lesson within the current unit.
              </p>
            </slot>
          </div>
          <div class="h-screen overflow-y-auto p-6">
            <slot name="body">
              <h3 class="text-lg font-semibold ml-12">
                Set evidence class / group
              </h3>
              <ul class="mt-6 mb-1 w-11/12 mx-auto">
                <li v-for="item in classes" 
                class="inline-block mr-2">
                  <button
                    type="button"
                    @click="setMoveClassName(item)"
                    class="
                      hover:bg-green-200
                      hover:text-white
                      font-bold
                      py-2
                      px-4
                      mb-2
                      rounded
                      icon
                    "
                    v-bind:class="
                      classModeButtonPressed(item)
                        ? 'bg-green-300 text-white'
                        : 'bg-gray-200 text-gray-500'
                    "
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>

              <h3 class="text-lg font-semibold ml-12 mt-5">
                Set evidence lesson
              </h3>
              <ul class="mt-6 mb-1 w-11/12 mx-auto">
                <li
                  v-for="(item, index) in orderedLessons"
                  :key="index"
                  class="inline-block mr-2"
                >
                  <button
                    type="button"
                    @click="setMoveLesson(item)"
                    class="
                      hover:bg-green-200
                      hover:text-white
                      font-bold
                      py-2
                      px-4
                      mb-2
                      rounded
                      icon
                    "
                    v-bind:class="
                      lessonMoveButtonPressed(item)
                        ? 'bg-green-300 text-white'
                        : 'bg-gray-200 text-gray-500'
                    "
                  >
                    {{ item.name }}
                  </button>
                </li>
              </ul>

              <button
                type="button"
                :disabled="
                  classMoveSelected == null && lessonMoveSelected == null
                "
                @click="moveEvidence"
                class="
                  mt-4
                  ml-10
                  bg-purple-400
                  hover:bg-purple-500
                  text-white
                  font-bold
                  py-2
                  px-4
                  rounded
                  disabled:opacity-75
                  disabled:cursor-not-allowed
                "
                title="Move Evidence"
              >
                <i class="fas fa-expand-arrows-alt mr-1"></i> Move Evidence
              </button>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </transition>

    <evidenceaddition
      v-if="showAdditionWindow"
      @close="closeAddition()"
      :classes="classes"
      :lessons="orderedLessons"
      :classPreset="selectedClass"
      :lessonPreset="lesson"
      :pupils="pupils"
    >
    </evidenceaddition>

    <div v-if="showEvidenceModal">
      <evidenceModal
        @close="showEvidenceModal = false"
        :lessonName="getLessonName(evidenceAsset)"
        :className="getClassName(evidenceAsset)"
        :notes="evidenceAsset.notes.String"
      >
        <evidencevideo
          v-if="evidenceMediatype === 'video'"
          reference="'ref_'+index"
          :url="loadFileModal()"
          width="100%"
          height="100%"
          style="min-height: 80vh !important"
        ></evidencevideo>
        <evidenceimage
          v-else
          :url="loadFileModal()"
          reference="'ref_'+index"
        ></evidenceimage>
      </evidenceModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EvidenceService } from "@/services/evidence.service";

import { wordNumericValue } from "@/assets/js/file-image.service"; // image service
import { calendarYearList } from "@/assets/js/utils";

const evidenceimage = () => import("@/components/EvidenceImage.vue");
const evidencevideo = () => import("@/components/EvidenceVideo.vue");
const evidenceModal = () => import("@/components/EvidenceModal.vue");
const dropdown = () => import("@/components/Dropdown.vue");
const savenotice = () => import("@/components/SaveNotice.vue");
const evidenceaddition = () =>
  import("@/components/curriculum/TheEvidenceAdd.vue");

export default {
  name: "evidence",
  props: {
    course: Object,
    lessons: Array,
    pupils: Array,
    classes: Array,
    selectedClass: Object,
    selectedLesson: Object,
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.filteredEvidences.length > 0;
    },
    lessonDropdown() {
      let all = { id: 0, name: "All Course Evidence" };
      var results = [...this.orderedLessons];
      results.unshift(all);
      return results;
    },
    orderedLessons() {
      return this.$orderBy(this.lessons, ["order", "id"]);
    },
    filteredEvidences() {
      var vm = this;
      let classChosen = vm.selectedClass;
      let lessonChosenId = vm.lesson.id;
      let displayChosen = vm.displayType;

      let evidences = vm.evidences

      if (
        classChosen.id === 0 &&
        lessonChosenId == 0 &&
        displayChosen.length == 0
      ) {
        return evidences;
      } else {
        var filtered = evidences.filter(function (e) {
          let filtered = true;

          if (classChosen.id > 0) {
            filtered = e.class === classChosen.name;
          }

          if (filtered) {
            if (lessonChosenId > 0) {
              filtered = e.lesson_id === lessonChosenId;
            }
          }

          if (filtered) {
            if (displayChosen.length > 0) {
              filtered = e.media_type == displayChosen;
            }
          }

          return filtered;
        });

        return filtered;
      }
    },
    criteriaList() {
      if (this.criteria.length > 0) {
        return this.criteria;
      }
    },
  },
  data: function () {
    return {
      errored: false,
      loading: true,
      data: [],
      evidences: [],
      folder: "",
      showEvidenceModal: false,
      evidenceAsset: {},
      evidenceMediatype: "",
      lesson:
        this.selectedLesson == undefined
          ? { id: 0, name: "All Course Evidence" }
          : this.selectedLesson,
      displayType: "",
      selectedEvidences: [],
      showMove: false,
      lessonMoveSelected: null,
      classMoveSelected: null,
      showAdditionWindow: false,

      isLoadingMore: false,
      page: 1,
      totalResultsCount: 0
    };
  },
  created() {
    this.folder = wordNumericValue(this.currentUser.schoolID);
  
  },
  mounted() {
    this.fetchEvidence();
  },
  methods: {
    loadFile(path) {
      return this.folder + "/" + path;
    },
    loadFileModal() {
      return this.folder + "/" + this.evidenceAsset.media_url;
    },
    showEvidenceAsset(item) {
      this.showEvidenceModal = true;
      this.evidenceAsset = item;
      this.evidenceMediatype = item.media_type;
    },
    loadMore() {
      console.log("LOAD MMORE MAN")
      this.isLoadingMore = true
      this.fetchEvidence()
    },
    fetchEvidence() {
      EvidenceService.getByCourse(this.course.id, this.page)
        .then((response) => this.buildEvidences(response.data))
        .catch((err) => {
          console.log("evidences " + err);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false
          this.isLoadingMore = false
          this.page = this.page + 1
        });
    },
    buildEvidences(data) {
      this.data = data;
      this.evidences = [...this.evidences, ...data.evidences]
      if (data.total_results != undefined) {
        this.totalResultsCount = data.total_results
      }

    },
    lessonSelect(value) {
      this.lesson = value;
      this.selectedEvidences = [];
    },
    hasNotes(evidence) {
      return evidence.notes != undefined && evidence.notes.String.length > 0
    },
    setDisplayType(value) {
      this.displayType = value;
      this.selectedEvidences = [];
    },
    getLessonName(evidence) {
      let value = this.lessons.find((l) => evidence.lesson_id === l.id);
      return value != undefined ? value.name : "";
    },
    getClassName(evidence) {
      return evidence.class.length > 1
        ? '<span>' + evidence.class + "</span>"
        : '<span class="text-red-600 py-1 rounded"><i class="fas fa-exclamation-circle pr-2"></i>Class not set</span>';
    },
    selectEvidence(evidence) {
      if (
        this.selectedEvidences.some((e) => e.identifier === evidence.identifier)
      ) {
        this.selectedEvidences.splice(
          this.selectedEvidences.findIndex(
            (item) => item.identifier === evidence.identifier
          ),
          1
        );
      } else {
        this.selectedEvidences.push(evidence);
      }
    },
    evidenceIsSelected(evidence) {
      let found = this.selectedEvidences.find(
        (e) => e.identifier === evidence.identifier
      );
      return found == undefined ? false : true;
    },
    closeMoveModal() {
      this.showMove = false;
      this.selectedEvidences = [];
    },
    setMoveClassName(item) {
      if (
        this.classMoveSelected == null ||
        this.classMoveSelected.id != item.id
      ) {
        this.classMoveSelected = item;
      } else {
        this.classMoveSelected = null;
      }
    },
    setMoveLesson(item) {
      if (
        this.lessonMoveSelected == null ||
        this.lessonMoveSelected.id != item.id
      ) {
        this.lessonMoveSelected = item;
      } else {
        this.lessonMoveSelected = null;
      }
    },
    lessonMoveButtonPressed(object) {
      return this.lessonMoveSelected == null
        ? false
        : this.lessonMoveSelected.id == object.id;
    },
    classModeButtonPressed(object) {
      return this.classMoveSelected == null
        ? false
        : this.classMoveSelected.id == object.id;
    },
    moveEvidence() {
      this.showMove = false;
      this.$swal({
        title: "Are you sure you want to move evidence?",
        text: "This will change all selected evidence!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, change!",
      }).then((result) => {
        if (result.value) {
          this.processMove();
        }
      });
    },
    processMove() {
      let vm = this;
      // loop selected evidence
      vm.selectedEvidences.forEach(function (ee, index) {
        if (vm.classMoveSelected != null) {
          ee.class = vm.classMoveSelected.name;
        }

        if (vm.lessonMoveSelected != null) {
          ee.lesson_id = vm.lessonMoveSelected.id;
        }

        // update evidence
        EvidenceService.update(ee)
          .then((response) => {
            vm.classMoveSelected = {};
            vm.lessonMoveSelected = {};
            vm.completion(ee);
          })
          .catch((err) => {
            vm.failure(err);
          });
      });
    },
    completion(evidence) {
      this.$refs.saveNotice.saved();
    },
    failure(error) {
      this.$refs.saveNotice.failed();
      console.log("Evidence update error " + error);
    },
    closeAddition() {
      this.fetchEvidence();
      this.showAdditionWindow = false;
    },
  },
  components: {
    evidenceimage,
    evidencevideo,
    dropdown,
    savenotice,
    evidenceaddition,
    evidenceModal,
  },
};
</script>

<style scoped>

</style>
