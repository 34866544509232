import { SchoolService, BaseService } from "@/services/school.service";

const EvidenceService = {
    getByCourse(courseId) {
        return SchoolService.getAll("courses/" + courseId + "/evidences")
    },

    getWithFilter(calendarYear, className, yearGroup, page) {
        return SchoolService.getAll("evidences?stored=" + calendarYear + "&class=" + className + "&yeargroups=" + yearGroup + "&page=" + page + "&results_per_page=50")
    },

    // crud

    add(payload) {
    	return SchoolService.create("evidences", payload)
    },

    update(result) {
    	return SchoolService.update("evidences", result.identifier, result)
    },


    delete(result) {
    	return SchoolService.delete("evidences", result.identifier, null)
    },

    // pupils

    addPupil(payload) {
        return SchoolService.create("evidences/pupils", payload)
    },

    deletePupil(result, object) {
       return SchoolService.delete("evidences/" + result.identifier + "/pupils", object.identifier, null) 
    },

    // outcomes

    addOutcome(payload) {
        return SchoolService.create("evidences/outcomes", payload)
    },

    deleteOutcome(result, object) {
       return SchoolService.delete("evidences/" + result.identifier + "/outcomes", object.identifier, null) 
    }
    
}

export { EvidenceService };