var aws = require('aws-sdk');

var rscBucketName = 'pepa.evdc';

function config() {

    var rscBucketRegion = 'eu-west-1';
    var rscIdentityPoolId = 'eu-west-1:233e356f-e3c9-4e3a-94ea-0f2450764304';

    AWS.config.update({
      region: rscBucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: rscIdentityPoolId
      })
    });

    var s3 = new AWS.S3({apiVersion: 'latest'});
    return s3
}



function upload(formData, identifier) {
  const photos = formData.getAll("photos");

  var file = photos[0];
  var path = wordNumericValue(identifier);

  var ext =
    Math.random()
      .toString(36)
      .substring(2, 9) +
    "." +
    file.name.split(".").pop();
  var fileName = file.name.replace(/\s/g, '_').toLowerCase() + ext;

  let remoteName = path + "/" + fileName;

  const filePath = "cognito/" + remoteName.trim();
  const type = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'].includes(file['type']) ? 'camera' : 'video'

  var params = {
    Bucket: rscBucketName,
    Key: filePath,
    ContentType: file.type,
    Body: file,
    ACL: "private",
    ServerSideEncryption: "AES256"
  };

  var s3 = config();
  return new Promise((resolve, reject) => {
    s3.putObject(params, function(err, res) {
      if (err) reject(err);
      else resolve({ path: remoteName, filename: fileName, type: type });
    });
  });
}


function wordNumericValue(n) {
    var alpha = "abcdefghijklmnopqrstuvwxyz".split('')
    var digits = Array.from(n.toString()).map(Number);

    var value = digits.map(v => {
         if (v >= 25) return "";
         return alpha[v];
    }).join("")

    var name = "pep" + value
    return name.toUpperCase()
}

const GetFile = {

  from(remoteName) {

    var s3 = config();

    const filePath = 'cognito/' + remoteName.trim();

    var headParams = {
        Bucket: rscBucketName,
        Key: filePath,
    };  
    
    var params = {
        Bucket: rscBucketName,
        Key: filePath,
        Expires: 60
    };  


    return new Promise((resolve, reject) => {
      try {    
            s3.headObject(headParams, function(err, data) {
           if (err) {
           reject(err) 
         } else {   
          s3.getSignedUrl('getObject', params, (err, url) => {
           if (err) reject(err)
           else resolve(url)
          })  

        }

      })

      } catch(e) {
        reject(e.message)
      }
 
    })
  }
 
}

export { GetFile, upload, wordNumericValue }